<template>
    <div type="text/x-template" id="modal-template" >
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">

                        <div class="container-fluid">
                            <div class="row p-3" style="background-color: #444444">
                                <div class="col-6">
                                    <h5 class="text-white mb-0">Geo Configuration</h5>
                                </div>
<!--                                <div class="col-6 d-flex justify-content-end">-->
<!--                                    <b-button variant="primary" size="sm" to="/clients" class="font-weight-bold" style="border-radius: 0px !important;">CLIENT MANAGEMENT</b-button>-->
<!--                                </div>-->
                            </div>
                            <div class="row p-2" style="background-color: #EBEBEB">
                                <div class="col-5">
<!--                                    <b-input-group>-->
<!--                                        <b-form-input style="border-radius: 0px !important;"></b-form-input>-->
<!--                                        <b-input-group-append>-->
<!--                                            <b-button style="border-radius: 0px !important;" variant="dark"><b-icon-search></b-icon-search></b-button>-->
<!--                                        </b-input-group-append>-->
<!--                                    </b-input-group>-->
                                </div>
                                <div class="col-6 d-flex justify-content-end">
<!--                                    <span class="mt-1 pr-3"><b-icon-sliders font-scale="1.5"></b-icon-sliders> Filters</span>-->
                                </div>
                                <div class="col-1 d-flex justify-content-end">
                                    <b-button variant="dark" style="border-radius: 0px !important;" @click="saveGeo"><strong>Save</strong></b-button>
                                </div>
                            </div>
                            <div class="row border-bottom">
                                <div class="col-12 col-md-12">
                                    <p class="m-2"><strong v-if="domain.client">Client Name: {{ domain.client.Name }}</strong></p>
                                </div>
                            </div>
                            <div class="row border-bottom">
                                <div class="col-4 col-md-4">
                                    <p class="m-2"><strong>Plan Type: {{ plan.PlanListing.Driven }}</strong></p>
                                </div>
                                <div class="col-4 col-md-4">
                                    <p class="m-2"><strong>Proxy Type: {{ plan.PlanListing.ProxyType }}</strong></p>
                                </div>
                                <div class="col-4 col-md-4">
                                    <p class="m-2"><strong>Plan Size: {{ plan.PlanListing.NumberOfClicks|convert }}</strong></p>
                                </div>
                            </div>
                            <div class="row border-bottom">
                                <div class="col-4 col-md-4" v-if="this.found" >
                                    <p class="m-2"><strong>Domain: {{  name }}</strong></p>
                                </div>
                                <div class="col-4 col-md-4">
                                    <p class="m-2"><strong>No. of Clicks: {{ clicks }}</strong></p>
                                </div>
<!--                                <div class="col-4 col-md-4" v-if="hideme">-->
<!--                                    <p class="m-2"><strong>Clicks Remaining: {{ clicks - estabClicks  }}</strong></p>-->
<!--                                </div>-->
                                <div class="col-4 col-md-4" >
                                    <p class="m-2"><strong>Clicks Remaining: {{ totalRemaining }}</strong></p>
                                </div>
                                <div class="col-4 col-md-4" >
                                    <p class="m-2"><strong>Percentage Remaining: {{ percentageOverflow }}</strong></p>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <b-table
                                :fields="fields"
                                head-variant="primary"
                                :items="geos">
                                    <template v-slot:cell(perClick)="row">
                                        <b-form-input :id="'range' + row.index" @change="updateClickDistribution(row.index)" v-model="row.item.perClick" type="range" step="1" min="0" max="100"></b-form-input>
                                    </template>
                                    <template v-slot:cell(total)="row">
                                        <span>{{ row.item.perClick }}%</span>
                                    </template>
                                </b-table>
                            </div>

                            <div class="row p-3" style="background-color: #656565">
                                <div class="col-6">
                                    <b-button variant="dark" size="sm" class="font-weight-bold" @click="$emit('closegeos')" style="border-radius: 0px !important;">BACK</b-button>
                                </div>
<!--                                <div class="col-6 d-flex hide justify-content-end">-->
<!--                                    <b-button variant="primary" size="sm" class="font-weight-bold" style="border-radius: 0px !important;">CONFIGURE DOMAIN</b-button>-->
<!--                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
// import { BIconSearch, BIconSliders } from 'bootstrap-vue'
import axios from "axios";
import swal from "sweetalert";

    export default {
        name: "geos",
        data() {
            return {
                clicks: '',
                totalRemaining:'',
                maxClicks: 0,
                index: '',
                name: '',
                forceRecomputeCounter: 0,
                found: false,
                percentageOverflow:100
            }
        },
        props: {
            geos: {
                type: Array,
                required: true
            },
            domain: {
                type: Object,
                required: true
            },
            plan: {
                type: Object,
                required: true
            },
            noc: {
                type: Number,
                required: true
            },
            nocd:{
              type: Number,
            },
            domain_nm: {
                type: Array,
                required: true
            }
        },
        components: {
            // BIconSearch,
            // BIconSliders,
        },
        filters: {
            convert: function(value) {
                if (value < 1000000) {
                    return value / 1000 + 'K'
                } else {
                    return value / 1000000 + 'M'
                }
            },
            convertG: function(value) {
                return value / 1000000000 + 'G'
            }
        },
        created() {
            console.log({noc: this.noc, nocd: this.nocd });
            this.$forceUpdate();
            this.domainName();
            this.clicks = this.noc;
            this.totalRemaining = (Number(this.noc) - Number(this.domain.NumberOfClicksDelivered));
            let allocatedPercentage = 0;
            for(let i=0; i<this.geos.length; i++){
                allocatedPercentage += Number(this.geos[i].perClick);
            }
            this.percentageOverflow = 100 - allocatedPercentage
        },
        methods: {
            saveGeo() {
                swal({ title: "Processing", text: 'Saving Geo', icon: "info", } )
                axios.post(`${this.$root.serverUrl}/admin/domain/domain/geos/click-distribution`, {domain_config_id: this.domain._id, geos: this.geos}).then((resp)=> {
                    if ( resp.data && resp.data.error ) {
                        swal({ title: "Notice", text: resp.data.error.message, icon:"info", } )
                    } else {
                        swal({ title: "Success", text: 'Geo Saved', icon: "success", } ).then(()=> {
                            this.$emit('closegeos')
                        })
                    }
                })
            },
            domainName() {
               for(let x in this.domain_nm){

                   if ( this.domain_nm[x]._id === this.domain.domain_id ) {
                      this.name = this.domain_nm[x].name
                       this.found = true
                   }else{
                       this.name = ''
                   }
               }


            },
            updateClickDistribution(index) {
                console.log(index);
                let allocatedPercentage = 0;
                for(let i=0; i<this.geos.length; i++){
                    if(index === i) continue;
                    allocatedPercentage += Number(this.geos[i].perClick);
                }
                const selectedPercentage = Number(this.geos[index].perClick);
                const availablePercentage = 100 - allocatedPercentage;
                if(selectedPercentage > availablePercentage) this.geos[index].perClick = availablePercentage;
                this.percentageOverflow = availablePercentage - this.geos[index].perClick;
                return;
                /*const item = this.geos[index];
                let per = item.perClick / 100
                const percentageValue = Math.round(this.clicks * per)
                this.maxClicks = this.clicks - this.estabClicks
                const percentageDifference = percentageValue - item.total;
                if ((percentageDifference > this.maxClicks)){
                    let overflow = percentageDifference - this.maxClicks;
                    item.total = percentageValue - overflow;
                    item.perClick = ((item.total * 100) / this.clicks);
                } else {
                    item.total = percentageValue;
                }*/

                //this.forceRecomputeCounter++;
            }
        },
        computed: {
            data() {
                return this.geos
            },
            fields() {
                return [
                    {
                        key: 'geo_country',
                        label: 'Country',
                        sortable: false
                    },
                    {
                        key: 'geo_state',
                        label: 'State',
                        sortable: false
                    },
                    {
                        key: 'geo_city',
                        label: 'City',
                        sortable: false
                    },
                    {
                        key: 'perClick',
                        label: 'Percentage Selector',
                        sortable: false
                    },
                    {
                        key: 'total',
                        label: 'Percentage',
                        sortable: false
                    }
                ]
            },
            //estabClicks() {
               /* this.forceRecomputeCounter;
                if ( this.geos.length !== 0 ) {
                   return this.geos.reduce( ( sum, curr ) => { return Number( sum ) + Number( curr.total ) }, 0)
                }
                return 0*/
            //},
        }
    }
</script>

<style scoped>

.form-control:focus {
    -webkit-box-shadow: 0 0 0 0rem;
    box-shadow: 0 0 0 0rem;
    border-color: #CED4DA;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: table;
    transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -webkit-transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 900px;
    margin: 0px auto;
    padding: 0px 0px 0px 0px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-leave-active .modal-container {
    transform: scale(1.1);
    -o-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
}

</style>
