<template>
    <div>
        <h6 v-for='data in value ' :key="data">{{ data }}</h6>
        <!-- <ejs-multiselect v-if="cities.length" id='multiselectSelectCity' :disabled="disable" :value="value" @change="send" :fields="cityFields" :allowFiltering="true" placeholder="Select Cities" :dataSource='cities'></ejs-multiselect> -->
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "CitySelect",
        props: ['country', 'state', 'value', 'index', 'planIdx', 'id', 'domid', 'disable'],
        data() {
            return {
                cities: [],
                cityFields: { text: 'city_name', value: 'city_name' },
            }
        },
        mounted() {
            this.getCities();
        },
        methods: {
            send(item) {
            //    alert(item.isInteracted)
               if(item.isInteracted){
                let data = {
                    
                    city: item.value,
                    country: this.country,
                    state: this.state,
                    _id: this.id,
                    domainId: this.domid,
                }
                this.$emit('send-city', data, this.index, this.planIdx)
               }
            
            },

            getCities() {
                this.cities = [];
                let request = {'apikey': this.$root.apikey,filters:{}};

                if(this.country) request.filters.country_iso_code = this.country;
                if(this.state) request.filters.subdivision_1_iso_code = this.state;

                return axios.get(`${this.$root.serverUrl}/admin/geos/city`, {params:request}).then(function(resp){
                    //Store the stats
                    this.cities = (resp.data)? resp.data.data : [];
                }.bind(this));
            }
        }
    }
</script>
