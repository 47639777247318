<template>
    <div>
            <h6 v-for='data in state ' :key="data">{{ data }}</h6>
        <!-- <ejs-multiselect v-if="states.length" id='multiselect' :value="state" @change="send" :fields="stateFields" :allowFiltering="true" placeholder="Select States" :dataSource='states'></ejs-multiselect> -->
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "CitySelect",
        props: ['country', 'state', 'index', 'planIdx', 'id', 'domid'],
        data() {
            return {
                states: [],
                stateFields: { text: 'city_name', value: 'state_name' },
            }
        },
        mounted() {
            this.getStates();
        },
        methods: {
            send(item) {
                let data = {
                    country: this.country,
                    state: item.value,
                    _id: this.id,
                    domainId: this.domid,
                }
                this.$emit('send-state', data, this.index, this.planIdx)
            },

            getStates() {

                this.states = [];
                var request = {'apikey': this.$root.apikey,filters:{}};

                if(this.country) request.filters.country_iso_code = this.country;

                return axios.get(`${this.$root.serverUrl}/admin/geos/state`, {params:request}).then(function(resp){
                    //Store the stats
                    this.states = (resp.data && resp.data.data)? resp.data.data : [];
                }.bind(this));
            }
        }
    }
</script>
