<template>
    <div type="text/x-template" id="modal-template">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="container">
                            <div class="row d-flex justify-content-end">
                                <b-icon-x class="mt-3 mr-3 font-weight-bold" style="cursor: pointer;" @click="$emit('close')"></b-icon-x>
                            </div>
                        </div>

                        <div class="container mt-4">
                            <div class="col-12 col-md-12">
                                <b-alert
                                    :show="dismissCountDown"
                                    dismissible
                                    :variant="alert.variant"
                                    @dismissed="dismissCountDown = 0"
                                    @dismiss-count-down="countDownChanged"
                                >
                                    <p class="m-0" v-html="alert.message" />
                                </b-alert>
                                <div class="col-3 col-md-3 mb-2">
                                    <b-button size="sm" variant="success" id="tool" @click="add"><b-icon-plus></b-icon-plus></b-button>
                                    <b-tooltip style="position: relative; z-index: 1;" target="tool" triggers="hover">Add Link Level</b-tooltip>
                                </div>
                                <b-table
                                    :fields="fields"
                                    :items="linklvlTOS"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                >
                                    <template v-slot:cell(LinkLevel)="row">
                                        <div>{{ row.item.LinkLevel = row.index + 1 }}</div>
                                    </template>
                                    <template v-slot:cell(Tos)="row">
                                        <b-form-input v-model="row.item.Tos" @change="edit = row.index" @blur="validateTimeOnSite(row.item.Tos)" v-mask="'##:##'" a type="text" size="sm" autocomplete="off"  placeholder="0:00"></b-form-input>
                                    </template>
                                    <template v-slot:cell(Mode)="row">
                                        <b-form-select v-model="row.item.Mode" size="sm" @change="edit = row.index" :options="options"></b-form-select>
                                    </template>
                                    <template v-slot:cell(Action)="row">
                                        <b-button v-if="!row.item._id" :disabled="!row.item.Tos && row.item.Mode" variant="success" size="sm" @click="saveTOS(row.item, row.index)" v-b-tooltip.hover.right title="Save TOS" ><b-icon-check font-scale="1"></b-icon-check></b-button>
                                        <b-button v-if="row.item._id && edit === row.index" class="mr-1" variant="warning" size="sm" @click="updateTOS(row.item, row.index)" v-b-tooltip.hover.right title="Update TOS" ><b-icon-pencil-fill variant="light" font-scale="1"></b-icon-pencil-fill></b-button>
                                        <b-button v-if="row.item._id" variant="danger" size="sm" @click="deleteTOS(row.item,row.index)" v-b-tooltip.hover.right title="Delete TOS" ><b-icon-trash font-scale="1"></b-icon-trash></b-button>
                                    </template>
                                </b-table>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="linklvlTOS.length"
                                    :per-page="perPage"
                                    align="center"
                                    size="sm">
                                </b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { BIconX, BIconTrash, BIconCheck, BIconPlus, BIconPencilFill } from 'bootstrap-vue'
import axios from 'axios'
import swal from "sweetalert"
import Vue from 'vue'
import VueMask from 'v-mask'

Vue.use(VueMask);

export default {
    name: "tos",
    data() {
        return {
            edit: '',
            perPage: 5,
            currentPage: 1,
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            linklvl:'',
            alert: {
                variant: '',
                message: '',
            }
        }
    },
    props: {
        linklvlTOS: {
            type: Array,
            required: true,
        },
        domain_config_id: {
            type: String,
            required: true
        }
    },
    created() {
  //       if(this.linklvl == '') this.linklvl = [...this.linklvlTOS]
  //       this.linklvl.splice(0,1)
  // console.log(this.linklvl)
  //       console.log(this.linklvlTOS)
    },
    components: { BIconX, BIconTrash, BIconCheck, BIconPlus, BIconPencilFill },
    computed: {
        fields() {
            return [
                {
                    key: 'LinkLevel',
                    label: 'Link Level',
                    sortable: false
                },
                {
                    key: 'Tos',
                    label: 'TOS',
                    sortable: false
                },
                {
                    key: 'Mode',
                    label: 'Mode',
                    sortable: false
                },
                {
                    key: 'Action',
                    label: 'Action',
                    sortable: false
                }
            ]
        },
        options() {
            return [
                { value: '', text: 'Select Mode', disabled: true },
                { value: 'strict', text: 'Strict' },
                { value: 'average', text: 'Average' },
            ]
        },
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        add() {
            this.linklvlTOS.push(
                {
                    Tos: '',
                    Mode: '',
                }
            )
        },
        validateTimeOnSite(time) {
            let reg = /^([0-5]?[0-9]|2[0-3]):[0-5][0-9]$/;
            if (!reg.test(time)) {
                this.alert.message = 'Invalid time entered. <strong>Recommended max time: 59:59</strong>'
                this.alert.variant = 'danger'
                this.showAlert()
                return false
            } else {
                return true
            }
        },
        saveTOS(tos, index) {
            tos.domain_config_id = this.domain_config_id
            if(tos.Mode == ""){
                swal({ title: "Mode Not Selected.", text: "Please  select a mode.", icon:"error" } )
            }else{
                swal({
                    title: "Loading",
                    text: "Sending Your Request Please Wait.",
                    icon:"info",
                    showConfirmButton: false,
                });
                axios.put(`${this.$root.serverUrl}/admin/domain/link-level-tos/`, tos).then((resp) => {
                    if ( resp.data && resp.data.error ) {
                        this.alert.variant = 'danger'
                        this.alert.message = resp.data.error.message
                        this.showAlert()
                        // location.reload()
                    } else {
                        //remove value at the index and replace it
                        this.linklvlTOS.splice(index,1,
                            {_id:resp.data.data.id,LinkLevel:resp.data.data.req.LinkLevel,
                                Mode:resp.data.data.req.Mode,
                                Tos:resp.data.data.req.Tos}
                        )
                        swal({title: 'Success', text: 'TOS added successfully.', icon: 'success'})
                    }
                })
            }

        },
        updateTOS(tos, index) {
            tos.domain_config_id = this.domain_config_id
            tos.id = tos._id
            //
            if(tos.Mode == ""){
                swal({ title: "Mode Not Selected.", text: "Please  select a mode.", icon:"error" } )
            }else{
                axios.post(`${this.$root.serverUrl}/admin/domain/link-level-tos/`, tos).then((resp) => {
                    if ( resp.data && resp.data.error ) {
                        this.alert.variant = 'danger'
                        this.alert.message = resp.data.error.message
                        this.showAlert()
                        // location.reload()
                    } else {
                        //remove value at the index and replace it
                        this.linklvlTOS.splice(index,1,
                            {_id:resp.data.data.req._id,LinkLevel:resp.data.data.req.LinkLevel,
                                Mode:resp.data.data.req.Mode,
                                Tos:resp.data.data.req.Tos}
                        )
                        this.$emit('modified')
                    }
                })
            }



        },
        deleteTOS(tos, index) {
            axios.delete(`${this.$root.serverUrl}/admin/domain/link-level-tos/${this.domain_config_id}/${tos._id}`).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    this.alert.variant = 'danger'
                    this.alert.message = resp.data.error.message
                    this.showAlert()
                } else {
                    this.linklvlTOS.splice(index,1)
                    this.$emit('modified')
                }
            })
        },
    }
}
</script>

<style scoped>

.form-control:focus {
    -webkit-box-shadow: 0 0 0 0rem;
    box-shadow: 0 0 0 0rem;
    border-color: #CED4DA;
}

.modal-mask {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: table;
    transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -webkit-transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 600px;
    margin: 0px auto;
    padding: 0px 0px 20px 0px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-leave-active .modal-container {
    transform: scale(1.1);
    -o-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
}

</style>
